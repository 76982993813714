import React, {useEffect} from 'react'
import env from '../../../Config'


import  './Webstyles.scss'


const WebDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='container-fluid base-pad pt-0'>
          <div>
            <img src={env.baseUrl + '/images/web-development-1.jpg'} className='img-fluid'/>
          </div>
          <div>
              <div className='navibluebox-row'>
                      <div className='bigcol'>
                        <h2 className='base-h1'> Web Development</h2>
                        <p>At UMS(Unique Mind Solution), we specialize in crafting cutting-edge web solutions tailored to meet your unique business needs. Our team of expert developers utilizes the latest technologies and best practices to deliver high-performance, user-friendly websites that drive results. </p>
                        <h3>Our Web Development services include:</h3>
                        <div className='d-lg-flex d-md-flex justify-content-between'>
                        <ul>
                          <li className='py-2'>Custom Web Development</li>
                          <li className='py-2'>Responsive Design</li>
                          <li className='py-2'>Website Maintenance and Support</li>
                        </ul>
                        <ul>
                        <li className='py-2'>E-Commerce Solutions</li>
                          <li className='py-2'>Content Management Systems (CMS)</li>
                        </ul>
                        </div>
                        
                      </div>
                      <div className='smallcol minus'>
                        <div className='icon-row'>
                          <div className='box'>
                              <div className='custom-web-icon'>
                              <img src={env.baseUrl + '/images/custom-web.png'} className='img-fluid'/>
                              </div>
                              <div>
                                <h4>custom web</h4>
                              </div>
                          </div>
                        </div>
                        <div className='icon-row'>
                          <div className='box'>
                              <div className='custom-web-icon'>
                              <img src={env.baseUrl + '/images/e-commerce.png'} className='img-fluid'/>
                              </div>
                              <div>
                                <h4>E-Commerce Solutions</h4>
                              </div>
                          </div>
                        </div>
                        <div className='icon-row'>
                          <div className='box'>
                              <div className='custom-web-icon'>
                              <img src={env.baseUrl + '/images/responsive.png'} className='img-fluid'/>
                              </div>
                              <div>
                                <h4>Responsive Design</h4>
                              </div>
                          </div>
                        </div>
                        <div className='icon-row'>
                          <div className='box'>
                              <div className='custom-web-icon'>
                              <img src={env.baseUrl + '/images/cms.png'} className='img-fluid'/>
                              </div>
                              <div>
                                <h4>Content Management Systems (CMS)</h4>
                              </div>
                          </div>
                        </div>
                        <div className='icon-row'>
                          <div className='box'>
                              <div className='custom-web-icon'>
                              <img src={env.baseUrl + '/images/support.png'} className='img-fluid'/>
                              </div>
                              <div>
                                <h4>Website Maintenance and Support</h4>
                              </div>
                          </div>
                        </div>
                      </div>
          </div>
          </div>
          <div className='bg-white web py-3'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-6 order-lg-1'>
                        <img src={env.baseUrl + '/images/custom-webdevelopment.jpg'} className='img-fluid img-cust'/>
                  </div>
                  <div className='col-lg-6 order-lg-2'>
                    <div className='r-box'>
                        <h3> Custom Web Development</h3>
                        <div className='d-flex justify-content-center align-items-center'>
                        <p>We design and develop fully customized websites from scratch, ensuring that every aspect aligns with your brand identity and business objectives.</p>
                        </div>
                        <div>
                          <h4>Why Choose Custom Web Development?</h4>
                          <ul>
                            <li>Personalization</li>
                            <li>Scalability</li>
                            <li>Optimized Performance</li>
                            <li>Enhanced Security</li>
                            <li>Integration Capabilities</li>
                          </ul>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div className='bg-gray py-3'>
              <div className='container'>
                  <div className='row'>
                    <div className='col-lg-6 order-lg-1 d-none d-lg-block'>
                      <div className='l-box'>
                          <h3> E-Commerce Solutions</h3>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p>Our E-Commerce development services empower businesses to establish a robust online presence, enabling seamless transactions and enhancing customer satisfaction.</p>
                            </div>
                            <div>
                              <h4>Why Choose E-Commerce Solutions?</h4>
                              <ul>
                                <li>Global Reach</li>
                                <li>24/7 Accessibility</li>
                                <li>Scalability</li>
                                <li>Personalized Shopping Experience</li>
                                <li>Data-Driven Insights</li>
                              </ul>
                            </div>
                      </div>
                    </div>
                    <div className='col-lg-6 order-lg-2 d-none d-lg-block'>
                          <img src={env.baseUrl + '/images/e-commerce.jpg'} className='img-fluid'/>
                    </div>
                    <div className='col-lg-6 d-block d-lg-none'>
                          <img src={env.baseUrl + '/images/e-commerce.jpg'} className='img-fluid'/>
                    </div>
                    <div className='col-lg-6 d-block d-lg-none'>
                      <div className='l-box'>
                      <h3> E-Commerce Solutions</h3>
                      <div className='d-flex justify-content-center align-items-center'>
                      <p>Our E-Commerce development services empower businesses to establish a robust online presence, enabling seamless transactions and enhancing customer satisfaction.</p>
                      </div>
                      <div>
                        <h4>Why Choose E-Commerce Solutions?</h4>
                        <ul>
                          <li>Global Reach</li>
                          <li>24/7 Accessibility</li>
                          <li>Scalability</li>
                          <li>Personalized Shopping Experience</li>
                          <li>Data-Driven Insights</li>
                        </ul>
                      </div>
                      </div>
                      
                    </div>
                  </div>
              </div>
          </div>
          <div className='bg-white web py-3'>
              <div className='container'>
              <div className='row'>
                  <div className='col-lg-6 order-lg-1 d-lg-block'>
                          <img src={env.baseUrl + '/images/responsive.jpg'} className='img-fluid img-cust'/>
                  </div>
                  <div className='col-lg-6 order-lg-2 d-lg-block'>
                      <div className='r-box'>
                          <h3>Responsive Design</h3>
                          <div className='d-flex justify-content-center align-items-center'>
                          <p>We create mobile-friendly websites that provide an optimal viewing and interaction experience across a wide range of devices and screen sizes.</p>
                          </div>
                          <div>
                            <h4>Why Choose Responsive Design?</h4>
                            <ul>
                              <li>Enhanced User Experience</li>
                              <li>Improved SEO Performance</li>
                              <li>Cost-Effectiveness</li>
                              <li>Future-Proofing</li>
                              <li>Accessibility Compliance</li>
                            </ul>
                          </div>
                      </div>
                  </div>
              </div>
              </div>
          </div>
          <div className='bg-gray py-3'>
              <div className='container'>
              <div className='row'>
                  <div className='col-lg-6 order-lg-1 d-none d-lg-block'>
                    <div className='l-box'>
                        <h3>Content Management Systems (CMS)</h3>
                        <div className='d-flex justify-content-center align-items-center'>
                    <p>We integrate user-friendly CMS platforms such as WordPress, Joomla, and Drupal, allowing you to easily manage and update your website content.</p>
                    </div>
                    <div>
                      <h4>Why Choose a Content Management System?</h4>
                      <ul>
                        <li>Ease of Use</li>
                        <li>Efficiency and Productivity</li>
                        <li>Flexibility and Customization</li>
                        <li>Scalability</li>
                        <li>SEO-Friendly</li>
                      </ul>
                    </div>
                    </div>
                    
                  </div>
                  <div className='col-lg-6 order-lg-2 d-none d-lg-block'>
                        <img src={env.baseUrl + '/images/cms.jpg'} className='img-fluid'/>
                  </div>
                  <div className='col-lg-6 d-block d-lg-none'>
                        <img src={env.baseUrl + '/images/cms.jpg'} className='img-fluid'/>
                  </div>
                  <div className='col-lg-6 d-block d-lg-none'>
                    <div className='l-box'>
                        <h3>Content Management Systems (CMS)</h3>
                        <div className='d-flex justify-content-center align-items-center'>
                    <p>We integrate user-friendly CMS platforms such as WordPress, Joomla, and Drupal, allowing you to easily manage and update your website content.</p>
                    </div>
                    <div>
                      <h4>Why Choose a Content Management System?</h4>
                      <ul>
                        <li>Ease of Use</li>
                        <li>Efficiency and Productivity</li>
                        <li>Flexibility and Customization</li>
                        <li>Scalability</li>
                        <li>SEO-Friendly</li>
                      </ul>
                    </div>
                    </div>
                    
                  </div>
                  
                </div>  
              </div>
              
          </div>
          <div className='bg-white web py-3'>
              <div className='container'>
              <div className='row'>
                  <div className='col-lg-6 order-lg-1 d-lg-block'>
                  <img src={env.baseUrl + '/images/website-maintenance.jpg'} className='img-fluid img-cust'/>
                  </div>
                  <div className='col-lg-6 order-lg-2 d-lg-block'>
                      <div className='r-box'>
                      <h3>Website Maintenance and Support</h3>
                          <div className='d-flex justify-content-center align-items-center'>
                          <p>We offer comprehensive website maintenance and support services to ensure that your website remains secure, up-to-date, and performing at its best.</p>
                          </div>
                          <div>
                          <h4>Our Website Maintenance and Support Services Offer</h4>
                          <ul>
                            <li>24/7 Monitoring</li>
                            <li>Technical Support</li>
                            <li>Regular Maintenance Tasks</li>
                            <li>Emergency Response</li>
                            <li>Consultation and Recommendations</li>
                          </ul>
                          </div>
                      </div>
                  </div>
              </div>
              </div>
          </div>
          
          
          
    </div>
  )
}

export default WebDevelopment