import React, { useState , useEffect} from "react";
import env from '../../Config';
import { Link } from 'react-router-dom';
// import emailjs from '@emailjs/browser';
import emailjs from 'emailjs-com';

const Contacts = () => {
  const [state, setState] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    messege: ""
  });
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Submitted Values", state);

    event.preventDefault();    //This is important, i'm not sure why, but the email won't send without it


    const templateParams = {
      from_name: state.name,
      to_name: "UMS",
      message: state.messege,
      reply_to: state.email,
      phone: state.phone
    };


    emailjs.send('service_wkae33s', 'template_nfjvkxo', templateParams, '8hTYz0W10IRtzwMvU')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      }, (err) => {
        console.log('FAILED...', err);
      });
  };
  return (
    <section className='contact-sec'>
      <div className='position-relative'>
        <img src={env.baseUrl + '/images/contact-in-br.jpg'} className='img-fluid' />
        <div className="bredcremb">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 text-center">
                <h4 className='page-name'>Contact Us</h4>
                <Link to='/'>Home</Link>
                <span className='mx-2'>/</span>
                <span>Contact Us</span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container mt-5 pt-lg-5">

        <div className="row" >
          <div className="col-lg-4  mb-4">
            <h4 className="blue-c">Reach Us</h4>
            <h6>Manjeera Trinity Corporate</h6>
            <span>First floor, Unit: 114, JNTU - Hitech Road, KPHB Colony, Kukatpally, Beside LuLu mall, </span>
            <span>Hyderabad - 500072, Telangana.</span>
          </div>
          <div className="col-lg-4 mb-4">
            <h4 className="blue-c">Call Us</h4>
            <p>Mobile: <Link to='callto:7702223007' className="text-decoration-none fs-5 green-c">+(91) - 770 2223 007</Link></p>
            <p>Mail: <Link to='mailto:contact@uniquemindsolutions.com' className="text-decoration-none green-c"> info@uniquemindsolutions.com</Link></p>
          </div>
          <div className="col-lg-4 mb-4">
            <h4 className="blue-c">Get In Touch</h4>
            <p>
              Monday - Friday: 09:00 to 18:00 (GMT +8). 
            </p>
            <p>Closed on Saturday, Sunday.</p>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="row mt-4 ">
          <div className="col-lg-6 pe-lg-5">
            <h1 className="orange-c">Have a project in mind?</h1>
            <p>Customer service team will get in touch with you in working days.</p>
            
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6 mb-3">
                  <input
                    type="text"
                    name="name"
                    class="form-control "
                    placeholder="Your Name*"
                    value={state.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <input
                    type="text"
                    name="phone"
                    class="form-control "
                    placeholder="Phone Number*"
                    value={state.phone}
                    onChange={handleInputChange}
                  />
                </div >
              </div >
              <div className="row">
                <div className="col-lg-6 mb-3">
                  <input
                    type="emial"
                    name="email"
                    class="form-control "
                    placeholder="Email Address*"
                    value={state.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <input
                    type="text"
                    name="subject"
                    class="form-control "
                    placeholder="Subject"
                    value={state.subject}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-lg-12 textalign">
                  <textarea name="messege"
                    class="form-control inputstule"
                    placeholder="messege"
                    value={state.messege}
                    onChange={handleInputChange}>

                  </textarea>
                </div>
              </div>
              <div className="my-3">
                <div className="">
                  <button type="submit" class="read-more-btn">Send Messege</button>
                </div>
              </div>
            </form>
          </div>


          <div className="col-lg-6">

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7610.8091869620575!2d78.38516084105454!3d17.488189383664864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa32cbea0334783f9%3A0x886a440a0d04da85!2sTrinity%20Work%20Hub!5e0!3m2!1sen!2sin!4v1707839938113!5m2!1sen!2sin" width="100%" height="400" allowFullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


          </div>
        </div>
      </div>






    </section>
  )
}

export default Contacts