import React,{useEffect} from 'react'
import env from '../../../Config'
import './graphicstyles.scss'

const GraphiDesign = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <div className='container-fluid base-pad pt-0'>
        <div>
            <div>
               <img src={env.baseUrl + '/images/graphic.jpg'} className='img-fluid'/>
            </div>
        </div>
        <div className='bg-white web p-3'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h3>Graphic Design Service</h3>
                        <p className='py-3'>At Unique Mind Solutions, we offer a wide range of professional graphic design services tailored to meet the unique needs of our clients. Our team of experienced designers combines creativity, technical expertise, and attention to detail to deliver visually stunning and impactful designs that elevate brands and captivate audiences. Whether you're looking for a new logo, eye-catching flyers, or innovative packaging designs, we have the expertise and creativity to bring your vision to life.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='bg-gray p-3'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-5 order-lg-2 d-none d-lg-block'>
                          <img src={env.baseUrl + '/images/logo-design.jpg'} className='img-fluid' alt='Logo Design'/>
                    </div>
                    <div className='col-lg-7 order-lg-1 d-none d-lg-block'>
                      <h3>Logo Design</h3>
                        <p>Your logo is the cornerstone of your brand identity and serves as a visual representation of your business. Our logo design services are tailored to help you create a memorable and timeless logo that communicates your brand values, personality, and unique selling proposition. From concept development to final execution, we work closely with you to ensure that your logo captures the essence of your brand and resonates with your target audience.</p>
                    </div>
                    <div className='col-lg-5 order-lg-1 d-block d-lg-none'>
                        <img src={env.baseUrl + '/images/logo-design.jpg'} className='img-fluid'/>
                    </div>
                    <div className='col-lg-7 order-lg-2 py-4 d-block d-lg-none'>
                    <div className='r-box'>
                    <h3>Logo Design</h3>
                        <p>Your logo is the cornerstone of your brand identity and serves as a visual representation of your business. Our logo design services are tailored to help you create a memorable and timeless logo that communicates your brand values, personality, and unique selling proposition. From concept development to final execution, we work closely with you to ensure that your logo captures the essence of your brand and resonates with your target audience.</p>
                    </div>
                   </div>
                    
                    
                </div>
            </div>
        </div>
        <div className='bg-white web p-3'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-5'>
                        <img src={env.baseUrl + '/images/brocher-design.jpg'} className='img-fluid'/>
                    </div>
                    <div className='col-lg-7 d-none d-lg-block'>
                      <h3>Flyer / Brochure Design</h3>
                        <p>Flyers and brochures are powerful marketing tools that help you communicate key messages, promote products or services, and attract customers. Our flyer and brochure design services are designed to help you create compelling and visually appealing marketing materials that stand out from the competition. Whether you need a single-page flyer or a multi-page brochure, we can help you design materials that effectively convey your message and drive engagement.</p>
                    </div>
                    <div className='col-lg-7 py-4 d-block d-lg-none'>
                    <div className='r-box'>
                    <h3>Flyer / Brochure Design</h3>
                        <p>Flyers and brochures are powerful marketing tools that help you communicate key messages, promote products or services, and attract customers. Our flyer and brochure design services are designed to help you create compelling and visually appealing marketing materials that stand out from the competition. Whether you need a single-page flyer or a multi-page brochure, we can help you design materials that effectively convey your message and drive engagement.</p>
                    </div>
                   </div>
                </div>
            </div>
        </div>
        <div className='bg-gray p-3'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-7 order-lg-1 d-none d-lg-block'>
                      <h3>Business Card Design</h3>
                        <p>Your business card is often the first impression you make on potential customers or clients. Our business card design services are focused on helping you create professional and memorable business cards that leave a lasting impression. From sleek and minimalist designs to bold and creative layouts, we can design business cards that reflect your brand identity and make a strong statement about your business.</p>
                    </div>
                    <div className='col-lg-5 order-lg-2 d-none d-lg-block'>
                        <img src={env.baseUrl + '/images/card-design.jpg'} className='img-fluid'/>
                    </div>
                    <div className='col-lg-5 order-lg-1 d-block d-lg-none'>
                        <img src={env.baseUrl + '/images/card-design.jpg'} className='img-fluid'/>
                    </div>
                    <div className='col-lg-7 order-lg-2 d-block d-lg-none '>
                    <div className='r-box'>
                        <h3>Business Card Design</h3>
                        <p>Your business card is often the first impression you make on potential customers or clients. Our business card design services are focused on helping you create professional and memorable business cards that leave a lasting impression. From sleek and minimalist designs to bold and creative layouts, we can design business cards that reflect your brand identity and make a strong statement about your business.</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='bg-white web p-3'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-5'>
                        <img src={env.baseUrl + '/images/animation.jpg'} className='img-fluid'/>
                    </div>
                    <div className='col-lg-7'>
                      <h3>Video / Animation / Illustration</h3>
                        <p>Visual content such as videos, animations, and illustrations can be powerful tools for engaging audiences and telling your brand story. Our video, animation, and illustration services are designed to help you create captivating and impactful visual content that resonates with your target audience. Whether you need an explainer video, a product demo, or custom illustrations for your website or marketing materials, we have the skills and expertise to bring your ideas to life.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='bg-gray p-3'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-7 order-lg-1 d-none d-lg-block'>
                      <h3>Packaging Design</h3>
                        <p>Packaging plays a crucial role in the success of your products, influencing purchasing decisions and enhancing brand perception. Our packaging design services are focused on helping you create innovative and eye-catching packaging designs that differentiate your products on the shelf and create a memorable brand experience for customers. From concept development to final production, we work closely with you to design packaging that not only protects your products but also communicates your brand values and resonates with your target audience.</p>
                    </div>
                    <div className='col-lg-5 order-lg-2 d-none d-lg-block'>
                        <img src={env.baseUrl + '/images/package-design.jpg'} className='img-fluid'/>
                    </div>
                    <div className='col-lg-5 d-block d-lg-none'>
                        <img src={env.baseUrl + '/images/package-design.jpg'} className='img-fluid'/>
                    </div>
                    <div className='col-lg-7 d-block d-lg-none'>
                    <div className='r-box'>
                        <h3>Packaging Design</h3>
                        <p>Packaging plays a crucial role in the success of your products, influencing purchasing decisions and enhancing brand perception. Our packaging design services are focused on helping you create innovative and eye-catching packaging designs that differentiate your products on the shelf and create a memorable brand experience for customers. From concept development to final production, we work closely with you to design packaging that not only protects your products but also communicates your brand values and resonates with your target audience.</p>
                    </div>
                      
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default GraphiDesign